<!--门票销售数据-->
<template>
    <div class="ticket-container">
        <div class="tip-box flex ac jb">
            <div class="flex ac">
                <div class="every-tip" @click="handleJump(0)">
                    门票销售数据
                </div>
                <!-- <div class="every-tip" @click="handleJump(1)">
                    门票退款数据
                </div> -->
                <div class="every-tip" @click="handleJump(2)">
                    客如云销售数据
                </div>
<!--                <div class="every-tip" @click="handleJump(3)">-->
<!--                    年卡销售数据-->
<!--                </div>-->
                <div class="every-tip-actived" @click="handleJump(4)">
                    嗨玩商城销售数据
                </div>
            </div>
            <div class="back-box" @click="goSlae()">
                返回上一页
            </div>
        </div>
        <div class="table-box">
            <div class="flex ac jb">
                <img class="title-img" src="../assets/sale/scxssj.png" alt="m">

                <div class="right-select cr flex ac jb">
                    <div>
                        <el-input v-model="selectObj.goods_name" placeholder="请输入商品名称"></el-input>
                    </div>
                    <div>
                        <el-select v-model="selectObj.mall_type_id" clearable placeholder="分类选择" size="mini">
                            <el-option
                                    v-for="item in Jqoptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-select v-model="selectObj.merchant_id" clearable placeholder="请选择商家名称" size="mini">
                            <el-option
                                    v-for="item in Jqoptions2"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-select v-model="selectObj.with_refund" clearable placeholder="是否包含退款数据" size="mini">
                            <el-option
                                    v-for="item in Jqoptions3"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="date-box">
                        <el-date-picker
                                v-model="date1"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                @change="changeTime"
                                :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </div>
                    <div>
                        <el-popover
                                placement="bottom"
                                width="480"
                                v-model="kjvisible1">
                            <div>
                                <div class="chooseYearBox flex ac">
                                    <div style="margin-right: 5px">选择年份:</div>
                                    <el-date-picker
                                            v-model="yearValue1"
                                            type="year"
                                            placeholder="选择年"
                                            @change="chooseYaer1"
                                            format="yyyy"
                                            value-format="yyyy"
                                    >
                                    </el-date-picker>
                                </div>
                                <div class="month-box flex fw" style="margin-top: 5px">
                                    <div style="margin-right: 5px">选择月份:</div>
                                    <div style="width: 80%">
                                        <el-checkbox-group v-model="monthValue1" size="mini" :disabled="checkDisable"
                                                           :max="1"
                                                           @change="handleMonth">
                                            <el-checkbox label="01" border>一月</el-checkbox>
                                            <el-checkbox label="02" border>二月</el-checkbox>
                                            <el-checkbox label="03" border>三月</el-checkbox>
                                            <el-checkbox label="04" border>四月</el-checkbox>
                                            <el-checkbox label="05" border>五月</el-checkbox>
                                            <el-checkbox label="06" border>六月</el-checkbox>
                                            <el-checkbox label="07" border>七月</el-checkbox>
                                            <el-checkbox label="08" border>八月</el-checkbox>
                                            <el-checkbox label="09" border>九月</el-checkbox>
                                            <el-checkbox label="10" border>十月</el-checkbox>
                                            <el-checkbox label="11" border>十一月</el-checkbox>
                                            <el-checkbox label="12" border>十二月</el-checkbox>
                                        </el-checkbox-group>
                                    </div>

                                </div>
                                <div class="month-box flex fw" style="margin-top: 5px">
                                    <div style="margin-right: 5px">选择节假日:</div>
                                    <div style="width: 80%">
                                        <el-checkbox-group v-model="holidayValue1" size="mini" :disabled="checkDisable2"
                                                           :max="1"
                                                           @change="handleHoliday">
                                            <div class="flex ac fw">
                                                <div v-for="item in holidayList" :key="item.id"
                                                     style="margin-right: 5px">
                                                    <el-checkbox :label="item.name" border/>
                                                </div>
                                            </div>
                                        </el-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="cannelKj(1)">取消</el-button>
                                <el-button type="primary" size="mini" @click="sumbitKj(1)">确定</el-button>
                            </div>
                            <div class="kj-box" slot="reference">
                                <p v-if="monthValue1.length>0">{{yearValue1}} {{monthValue1.toString()}}</p>
                                <p v-else-if="holidayValue1.length>0">{{yearValue1}} {{holidayValue1.toString()}}</p>
                                <p v-else>快捷筛选</p>
                            </div>
                        </el-popover>
                    </div>
                    <div class="rig-btn mr15" @click="handleSelect">
                        搜索
                    </div>
                    <div class="rig-btn" @click="handleDownload()">
                        下载报表
                    </div>
                </div>
            </div>
            <div class="main-info">
                <div class="table-info" style="height: 640px;margin-bottom: 24px">
                    <div class="table-head flex ac">
                        <div class="head1">商品名称</div>
                        <div class="head2">分类</div>
                        <div class="head3">销售数量</div>

                        <div class="head4">积分/优惠券抵扣总金额</div>
                        <div class="head5">销售单价</div>
                        <div class="head6">总销售金额</div>
                        <div class="head7">商户名称</div>
                    </div>

                    <div class="imp-info">
                        <div class="table-info flex ac" v-for="(item,index) in ticketList" :key="index">
                            <div class="head1">{{item.goods_name}}</div>
                            <div class="head2">{{item.type_name}}</div>
                            <div class="head3">{{item.total_num}}</div>
                            <div class="head4"> <span v-if="item.total_promo_price">¥{{item.total_promo_price}}</span> </div>
                            <div class="head5"> <span v-if="item.unit_price">¥{{item.unit_price}}</span> </div>
                            <div class="head6"> <span v-if="item.total_price"> ¥{{item.total_price}}</span></div>
                            <div class="head7">{{item.merchant_name}}</div>
                        </div>
                    </div>
                </div>
                <el-pagination
                        class="tc"
                        background
                        layout="prev, pager, next"
                        :page-size="selectObj.limit"
                        :current-page="selectObj.page"
                        :background="true"
                        :total="total"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        tjMallShopList,
        tjSaleTypeDetail,
        tjMallTypeList
    } from "@/api";
    import {parseTime, getCurrentMonthFirst, getCurrentMonthLast, formatTime} from '../utils'
    import {tjHolidayList} from "../api";


    export default {
        name: '',
        data() {
            return {
                name: '',
                Jqoptions: [],
                Jqoptions2: [],
                Jqoptions3: [
                    {id:'1',name:'包含'},
                    {id:'2',name:'不含'},
                ],
                Jqvalue: '',
                date1: [getCurrentMonthFirst(),getCurrentMonthLast()],
                total: 100,
                selectObj: {
                    type: 2,
                    goods_name: '',
                    mall_type_id: null,
                    with_refund: '',
                    merchant_id: '',
                    start_time: getCurrentMonthFirst(),
                    end_time: getCurrentMonthLast(),
                    page: 1,
                    limit: 15,
                },
                ticketList: [],
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.selectDate = minDate.getTime()
                        if (maxDate) {
                            this.selectDate = ''
                        }
                    },
                    disabledDate: (time) => {
                        if (this.selectDate !== '') {
                            const one = 3*31 * 24 * 3600 * 1000
                            const minTime = this.selectDate - one
                            const maxTime = this.selectDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    }
                },
                kjvisible1: false,
                yearValue1: new Date().getFullYear().toString(),
                monthValue1: [],
                holidayList: [],
                holidayValue1: [],
                checkDisable: false,
                checkDisable2: false,
                tHeader: ['商品名称', '分类', '销售数量', '积分/优惠券抵扣总金额', '销售单价', '总销售金额', '商户名称'],
                // 需要导出的部分
                filterVal: ['goods_name', 'type_name', 'total_num', 'total_promo_price', 'unit_price', 'total_price', 'merchant_name']
            }
        },
        created() {
            this.selectObj.end_time = formatTime(new Date(), "yyyy-MM-dd");
            this.selectObj.start_time = formatTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30), "yyyy-MM-dd");
            this.date1 = [this.selectObj.start_time, this.selectObj.end_time];
            this.getTjHolidayList(new Date().getFullYear());
            this.getTjScenicSpotList()
            this.getTjScenicSpotList2()
            this.getTjSaleTypeDetail(this.selectObj)
        },
        methods: {
            // 获取商家数据
            getTjScenicSpotList() {
                tjMallShopList().then(res => {
                    this.Jqoptions2 = []
                    this.Jqoptions2 = res.data
                    // const obj = {
                    //     id: 0,
                    //     name: '全部商家',
                    // }
                    // this.Jqoptions2.unshift(obj)
                })
            }, // 获取分类数据
            getTjScenicSpotList2() {
                tjMallTypeList().then(res => {
                    this.Jqoptions = []
                    this.Jqoptions = res.data
                })
            },
            // 获取数据
            getTjSaleTypeDetail(selectObj) {
                tjSaleTypeDetail({...selectObj}).then(res => {
                    this.total = res.data.total
                    this.ticketList = res.data.list
                })
            },
            handleSelect() {
                this.selectObj.page = 1
                this.getTjSaleTypeDetail(this.selectObj)
            },
            handleDownload() {
                let exportArray = []
                tjSaleTypeDetail({...this.selectObj, page: 1, limit: this.total}).then(res => {
                    exportArray = res.data.list
                    import('../vendor/Export2Excel').then(excel => {
                        const tHeader = this.tHeader
                        console.log('tHeader', tHeader)
                        const filterVal = this.filterVal
                        const data = this.formatJson(filterVal, exportArray)
                        excel.export_json_to_excel({
                            header: tHeader,
                            data,
                            filename: '商城销售数据表',
                            autoWidth: true,
                            bookType: 'xlsx'
                        })
                    })
                })
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    if (j === 'timestamp') {
                        return parseTime(v[j])
                    } else {
                        return v[j]
                    }
                }))
            },
            changeTime(val) {
                if (val && val.length > 0) {
                    this.selectObj.start_time = val[0]
                    this.selectObj.end_time = val[1]
                } else {
                    this.selectObj.start_time = '',
                    this.selectObj.end_time = ''
                }
            },
            handleJump(val) {
                switch (val) {
                    case 0 :
                        this.$router.push('/sale/ticketData');
                        break;
                    case 1 :
                        this.$router.push('/sale/ticketRefund');
                        break;
                    case 2 :
                        this.$router.push('/sale/secondSale');
                        break;
                    case 3 :
                        this.$router.push('/sale/cardSale');
                        break;
                    case 4 :
                        this.$router.push('/sale/shopSale');
                        break;
                }
            },
            goSlae() {
                this.$router.push('/sale');
            },
            handleCurrentChange(val) {
                this.selectObj.page = val
                this.getTjSaleTypeDetail(this.selectObj)

            },
            // 获取节假日列表
            getTjHolidayList(year) {
                tjHolidayList({year: year}).then(res => {
                    this.holidayList = res.data
                })
            },
            // 游客总量-日期
            handleDate1() {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.getTjSaleMonthRank();
            },
            chooseYaer1(val) {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.checkDisable = false
                this.checkDisable2 = false
                this.getTjHolidayList(val)
                if (this.monthValue1.length > 0) {
                    var lasyDay = ''
                    lasyDay = this.getLastDay(val, this.monthValue1.toString())
                    console.log('lasyDay', lasyDay)
                    this.date1 = [val + '-' + this.monthValue1.toString() + '-01', val + '-' + this.monthValue1.toString() + '-' + lasyDay];
                }
            },

            handleMonth(val) {
                if (val.length > 0) {
                    this.checkDisable2 = true
                    var lasyDay = ''
                    lasyDay = this.getLastDay(this.yearValue1, val.toString())
                    console.log('lasyDay', lasyDay)
                    this.date1 = [this.yearValue1 + '-' + val.toString() + '-01', this.yearValue1 + '-' + val.toString() + '-' + lasyDay];
                } else {
                    this.checkDisable2 = false
                }
            },
            handleHoliday(val) {
                if (val.length > 0) {
                    this.checkDisable = true
                    this.holidayList.forEach(item => {
                        if (item.name == val.toString()) {
                            this.date1 = [item.start_date, item.end_date];
                        }
                    })
                } else {
                    this.checkDisable = false
                }
            },
            //某年某月最后一天
            getLastDay(year, month) {
                var nyear = year;
                var nmonth = month++;
                if (month > 12) {
                    nmonth -= 12;
                    nyear++;
                }
                var n_date = new Date(nyear, nmonth, 1);
                return (new Date(n_date.getTime() - 1000 * 60 * 60 * 24)).getDate();
            },
            cannelKj(val) {
                if (val == 1) {
                    this.kjvisible1 = false
                }
            },
            sumbitKj(val) {
                if (val == 1) {
                    if (!this.yearValue1) {
                        this.$message.error('请选择年份')
                        return false
                    }
                    if (this.monthValue1.length == 0 && this.holidayValue1.length == 0) {
                        this.$message.error('请选择月份或者节假日')
                        return false
                    }
                    this.kjvisible1 = false
                    this.selectObj.start_time = this.date1[0]
                    this.selectObj.end_time = this.date1[1]
                    this.getTjSaleTypeDetail(this.selectObj)
                }
            },
        },
        /**加载完组件时执行(加载完成之后执行)*/
        mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .ticket-container {
        .tip-box {
            cursor: pointer;
            margin-bottom: 23px;

            .every-tip {
                width: 176px;
                height: 41px;
                background: url("../assets/sale/tipSaleBg.png") no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                color: #9bb0cc;
                line-height: 41px;
                margin-right: 32px;
            }

            .every-tip-actived {
                width: 176px;
                height: 41px;
                background: url("../assets/sale/tipSaleActived.png") no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #ffbb00;
                line-height: 41px;
                margin-right: 32px;
            }

            .back-box {
                width: 108px;
                height: 32px;
                background: url("../assets/sale/backIcon.png") no-repeat;
                background-size: 100% 100%;
                opacity: 0.7;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 32px;
            }
        }

        .table-box {

            width: 1840px;
            height: 810px;
            /*opacity: 0.6;*/
            background: #0a1931;
            border: 1px solid #0372a6;
            padding: 24px 32px;

            .title-img {
                width: 400px;
                height: 48px;
            }

            .right-select {
                width: 1400px;

                .rig-btn {
                    width: 96px;
                    height: 32px;
                    background: url("../assets/sale/selectIcon.png") no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #022560;
                    line-height: 32px;
                }
            }
        }

        .main-info {
            margin-top: 24px;

            .table-head {
                width: 1776px;
                height: 40px;
                background: #092859;
                border: 0.5px solid #3e85b7;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                color: #ffffff;

                .head1 {
                    padding-left: 24px;
                    width: 463px;
                }

                .head2 {
                    width: 195px;
                }

                .head3 {
                    width: 165px;
                }

                .head4 {
                    width: 256px;
                }

                .head5 {
                    width: 230px;

                }

                .head6 {
                    width: 229px;

                }
                .head7 {
                    width: 238px;

                }

                img {
                    width: 6px;
                    height: 9px;
                    margin-left: 3px;
                }
            }

            .imp-info {
                .table-info:nth-child(odd) {
                    width: 1776px;
                    height: 40px;
                    background: #15223c;
                }

                .table-info:nth-child(even) {
                    width: 1776px;
                    height: 40px;
                    background: rgba(21, 34, 60, 0.60);
                }

                .table-info {
                    font-size: 14px;
                    font-weight: normal;
                    text-align: left;
                    color: #ffffff;

                    .head1 {
                        padding-left: 24px;
                        width: 463px;
                        font-size: 14px;
                        text-align: left;
                        color: #3ce6f0;
                    }

                    .head2 {
                        width: 195px;
                    }

                    .head3 {
                        width: 165px;
                    }

                    .head4 {
                        width: 256px;
                    }

                    .head5 {
                        width: 230px;

                    }

                    .head6 {
                        width: 229px;

                    }
                    .head7 {
                        width: 238px;

                    }
                }
            }
        }

    }


    .tc {
        text-align: center;
    }

    .mt15 {
        margin-top: 15px;
    }

    .mr15 {
        margin-right: 15px;
    }

    .flex {
        display: flex;
    }

    .ac {
        align-items: center;
    }

    .jb {
        justify-content: space-between;
    }

    .fw {
        flex-wrap: wrap;
    }

    .bbox {
        box-sizing: border-box;
    }

    .cr {
        cursor: pointer;
    }
    .kj-box {
        background: url('../assets/kuaijie-box.png') no-repeat;
        background-size: 100% 100%;
        width: 96px;
        height: 33px;
        margin-left: 12px;
        cursor: pointer;

        p {
            text-align: center;
            line-height: 33px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
        }
    }

</style>
<style lang="scss">
    .table-box .el-input {
        width: 200px;
    }

    .table-box .el-range-input {
        background: #042C60 !important;
        color: #37ECFF !important;
    }


    .table-box .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042C60 !important;
        border: none !important;
        color: #37ECFF !important;
    }

    .date-box .el-input__inner {
        width: 240px !important;
    }

    .table-box .el-input__icon {
        line-height: 28px !important;
    }

    .main-info .el-pager li {
        background: #042c60 !important;
        color: #FFFFFF !important;
    }

    .main-info .el-pager .active {
        background: #37ecff !important;
        color: #022560 !important;
    }

    .main-info .btn-prev, .btn-next {
        background-color: #042c60 !important;
        color: #FFFFFF !important;
    }
</style>
